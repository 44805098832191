nav.slideshow {
  .single-collection & {
    margin-top: ceil($grid-gutter-width*.66);
  }
  a {
    display: block;
    border-top: 2px solid $gray-b;
    border-bottom: 2px solid $gray-b;
    padding: $grid-gutter-width/2 0 $grid-gutter-width/3;
    color: $black;
    text-decoration: none;
    text-transform: uppercase;
    line-height: $font-size-2xl;
    font-weight: $font-weight-black;
    font-size: $font-size-lg;
    &:hover {
      color: $link-color;
    }
    img {
      float: right;
      width: $font-size-2xl;
      margin: -4px 5px 0 $grid-gutter-width/3;
    }
  }
}
.widget {
  &.sponsors {
    @extend .section-30-60;
    text-align: center;
    p {
      text-transform: uppercase;
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      margin-bottom: $grid-gutter-width !important;
    }
    h3 {
      @extend .block-title;
    }
    img {
      display: block;
      margin: 0 auto $grid-gutter-width auto;
      width: 80%;
      max-width: 400px;

    }
    a {
      display: block;
      text-align: center;
    }
    .single-exhibition & {
      p,img {
        margin-bottom: $grid-gutter-width*2 !important;
      }
    }
  }
}
