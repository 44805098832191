.footer-callout {
  position: relative;
  @extend .bg-gray;
  color: $brand-primary;
  .callout {
    background: url(../images/bg/weather-vane.png) no-repeat right bottom;
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    @media (max-width: $screen-xs-max) {
      padding-top: $grid-gutter-width*2;
      padding-bottom: $grid-gutter-width/2;
    }
    a {
      display: block;
      line-height: 1.1;
      font: $font-size-lg $font-family-serif;
      color: $brand-primary;
      text-decoration: none;
      padding-right: 110px;
      &:hover {
        color: $black;
      }
      @media (min-width: $screen-md-min) {
        font-size: $font-size-xl;
      }
    }
  }
  .backtotop {
    position: absolute;
    top: -300px;
    right: 20px;
    z-index: 10;
    display: block;
    width: $carousel-control-width;
    min-height: $carousel-control-width;
    background-color: rgba($gray-3,.7);
    line-height: 1.1;
    font-size: $font-size-2xs;
    text-align: center;
    color: rgba($gray,.8);
    padding: 5px;
    &:hover {
      background-color: rgba($gray-3,.9);
      color: $gray;
      text-decoration: none;
    }
    i {
      font-size: $font-size-xl;
      display: block;
      line-height: .8;
    }
  }
}
.content-info {
  @extend .bg-primary;
  @extend .section-30-60;
  color: $footer-text-color;
  font: $font-size-xs $font-family-sans-alt;
  line-height: 1.4545;
  a {
    color: $footer-text-color;
    text-decoration: none;
    &:hover {
      color: $white;
    }
  }
  nav {
    ul {
      list-style: none;
      margin: 0 0 1em;
      padding: 0;
      line-height: 1.25;
      li {
        margin-bottom: .3em;
      }
    }
  }
  .col {
    margin-bottom: $grid-gutter-width/2;
    @media (max-width: $screen-sm-max) {
      &:nth-child(3) {
        clear: left;
      }
    }
  }
  .nav-primary {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    .sub-menu {
      display: none;
    }
  }
  .nav-social {
    li {
      display: inline-block;
      margin-right: 5px;
      a.ss-icon {
        font-size: $font-size-2xl;
      }
    }
  }
  .copyright {
    margin:$grid-gutter-width/2 0 $grid-gutter-width 0;
    text-transform: uppercase;
  }
}
