a[name=top] {
  position: absolute;
  width: 1px;
  height: 1px;
  left: -300px;
  top: 0;
}
.page-header {
  @extend .image-fullwidth;
  padding: 0;
  margin: 0;
//   margin: -$navbar-height 0 0 0;
  border: none;
  position: relative;
  img {
//     margin-top: -$navbar-height;
  }
/*
  .carousel {
    margin-bottom: -$navbar-height;
    .carousel-inner {
      overflow: inherit;
      top: -$navbar-height;
    }
  }
*/
  @media (max-width: $screen-xs-max) {
    margin-top: 0;
    img {
      margin-top: 0;
    }
/*
    .carousel {
      margin-bottom: 0;
      .carousel-inner {
        overflow: hidden;
        top: 0;
      }
    }
*/
  }
  .header-text {
    @extend .image-text-overlay;
    .page-id-19320 & {
      p {
        margin-bottom: 0;
        background-color: transparent;
      }
    }
  }
  .header-text-float {
    background-color: $white-true;
    padding: $grid-gutter-width 0;
    p.page-title {
      font: 700 $font-size-small $font-family-sans;
      border-bottom: 1px solid $black;
      text-transform: uppercase;
      line-height: 1;
      margin: 0 0 .5em;
      padding-bottom: .5em;
      color: $gold;
      @media (min-width: $screen-sm-min) {
        font-size: $font-size-large;
      }
    }
    h1,h2 {
      margin: 0 0 .25em;
      line-height: 1;
      font-size: $font-size-h4;
      @media (min-width: $screen-sm-min) {
        font-size: $font-size-h2;
      }
    }
  }
  &+.page-header-caption {
    margin-top: $grid-gutter-width/2;
    margin-bottom: $grid-gutter-width/2;
    font: $font-size-2xs $font-family-sans-alt;
  }
  a {
    text-decoration: none;
  }
}
.cartbar {
  color: $white-true;
  background-color: $black;
  background-color: rgba($black,.9);
  height: $navbar-height-sm;
  text-align: right;
  padding-top: ceil($grid-gutter-width*.66);
  a {
    text-decoration: none;
    color: $white-true;
    &:hover {
      color: $link-color;
    }
  }
  @media (min-width: $screen-sm-min) {
    padding-top: $grid-gutter-width;
    height: $navbar-height;
    margin-top: $navbar-height;
  }

}
.banner {
	border: none;
	margin-bottom: 0;
	height: $navbar-height-full;
	&.affix {
  	position: relative;
	}
	@media (min-width: $screen-sm-min) {
    position: absolute;
    width: 100%;
    top:0;
    left: 0;
    .admin-bar & {
      top:32px;
    }
    &.affix {
      position: fixed;
      height: $navbar-height-sm;
      .ancillary {
        display: none;
      }
    }
  }
  @media (max-width: $screen-sm-max) {
  	height: $navbar-height-full-sm;
  }
  @media (max-width: $screen-xs-max) {
  	height: $navbar-height-full-xs;
  	background-color: $brand-primary;
  }
	.ancillary {
  	@extend .bg-primary;
  	height: $navbar-height-ancillary;
  	color: $gray-3;
  	font-weight: bold;
  	padding-top: $navbar-height-ancillary/3;
  	line-height: 1;
  	.detailed-hrs {
    	margin: -2px 0 0;
    	float: left;
    	font-size: $font-size-xs;
  	}
  	@media (max-width: $screen-sm-max) {
    	font-size: $font-size-sm;
    	padding-top: 10px;
    	padding-bottom: 5px;
    	height: $navbar-height-ancillary-sm;
    	.detailed-hrs {
      	margin: -5px 0 0;
      	position: absolute;
      	font-size: 8px;
    	}
    }
  	@media (max-width: $screen-xs-max) {
    	.detailed-hrs {
      	font-size: $font-size-2xs;
      	width: 80%;
    	}
    }
  	nav {
    	float: right;
    	position: relative;
    	ul {
      	margin: 0 $grid-gutter-width 0 0;
      	padding: 0;
      	list-style: none;
      	text-align: right;
        @media (min-width: $screen-md-min) {
        	margin: 0 $grid-gutter-width*2 0 0;
        }
      	li {
        	display: inline-block;
        	margin-left: $grid-gutter-width;
        	@media (max-width: $screen-sm-max) {
          	margin-left: $grid-gutter-width/2;
          }
        	a {
          	color: $white;
          	text-decoration: none;
          	&:hover {
            	color: $gray-3;
          	}
        	}
        	&.active {
          	font-weight: $font-weight-black;
        	}
      	}
    	}
    	#searchbar {
        position: absolute;
        top: -5px;
        right: 0;
        width: $grid-gutter-width;
        height: ceil($grid-gutter-width*.66);
        @media (min-width: $screen-md-min) {
          top: -10px;
          height: $grid-gutter-width;
        }
        #searchbar-label {
          width: $grid-gutter-width;
          height: ceil($grid-gutter-width*.66);
          z-index: 20;
          position: relative;
          margin-top: 3px;
          @media (min-width: $screen-md-min) {
            height: $grid-gutter-width;
            margin-top: 10px;
          }
          label {
            width: $grid-gutter-width;
            height: ceil($grid-gutter-width*.66);
            font-size: $font-size-sm;
            color: $white;
            cursor: pointer;
            text-align: right;
            @media (min-width: $screen-md-min) {
              font-size: $font-size-base;
              height: $grid-gutter-width;
            }
            &:hover {
            	color: $gray-3;
            }
          }
        }
        #searchbar-input {
          position: absolute;
          top: 0;
          right: $grid-gutter-width;
          width: 350px;
          height: ceil($grid-gutter-width*.66);
          @media (min-width: $screen-md-min) {
            height: $grid-gutter-width;
            width: 450px;
          }
          z-index: -1;
          overflow: hidden;
          input {
            display: block;
            position: absolute;
            top: 0;
            right: -350px;
            width: 350px;
            height: 100%;
            margin: 0;
            padding: 5px 10px 0px;
            border: none;
            border-bottom: 1px solid $white;
            background-color: $brand-primary;
            color: $gray;
            font-size: $font-size-sm;
            font-weight: $font-weight-light;
            @media (min-width: $screen-md-min) {
              font-size: $font-size-base;
              right: -450px;
              width: 450px;
            }
            backface-visibility: none;
            border-radius: 0;
            transition: right 0;
            &:focus {
              outline: none;
            }
          }
          &.focus {
            z-index: 20;
            input {
              right: 0;
              transition: right 0.5s;
            }
          }
        }
      }
  	}
	}
	.navbar-header {
		.navbar-brand {
  		padding-top: $grid-gutter-width/2;
  		padding-bottom: $grid-gutter-width/2;
    	position: relative;
    	width: 206px;
  		img {
    		position: absolute;
			  height: 50px;
			  width: 176px;
      }

		}
		.navbar-toggle {
  		border: none;
//   		margin: -20px 10px 0 0;
  		margin: 15px 10px 0 0;
  		.icon-bar {
        width: $grid-gutter-width;
        height: 4px;
        border-radius: 0;
      }
      .icon-bar + .icon-bar {
        margin-top: 4px;
      }
		}
		@media (max-width: $screen-xs-max) {
    	height: $navbar-height-sm;
  		.navbar-brand {
    		padding-top: $grid-gutter-width/3;
    		padding-bottom: $grid-gutter-width/3;
        width: 171px;
        height: $navbar-height-sm;
    		img {
  			  height: 40px;
  			  width: 141px;
        }
      }
    }
  }
  .navbar-nav {
    >li {
      >a {

      }
    }
    @media (min-width: $screen-sm-min) {
      float: right;
      font-size: $font-size-sm;
      > li {
        a {
          padding: 32px 8px 26px;
        }
        &.active {
          font-weight: $font-weight-bold;
        }
        &:last-child {
          a {
            padding-right: 0;
          }
        }
        &.dropdown {
          position: inherit;
          .dropdown-menu {
            border: none;
            width: 100%;
            ul {
              padding: $grid-gutter-width/3 0;
              list-style: none;
              li {
                @extend .col-sm-3;
                margin-top: $grid-gutter-width/3;
                margin-bottom: $grid-gutter-width/3;
                a {
                  padding: 0;
                  text-transform: uppercase;
                  text-decoration: none;
                  color: $dropdown-link-color;
                  font-weight: $font-weight-bold;
                  &:hover {
                    color: $dropdown-link-hover-color;
                  }
                }
                &.current {
                  a {
                    font-weight: $font-weight-black;
                  }
                }
                &:nth-child(4n+5) {
                  clear: left;
                }
              }
            }
          }
        }
      }
    }
    @media (min-width: $screen-md-min) {
      font-size: $font-size-base;
      > li {
        a {
          padding-left: $grid-gutter-width/2;
          padding-right: $grid-gutter-width/2;
        }
      }
    }
    @media (min-width: $screen-lg-min) {
      font-size: $font-size-lg;
    }
  }
  @media (max-width: $screen-xs-max) {
  	.navbar-collapse {
    	background-color: $white;
    	background-color: rgba($white,.95);
    	border: none;
    	.navbar-nav {
  			>li {
  				>a {
  					color: $gray-3;
  					&:hover {
    					color: $link-color;
  					}
  				}
  			}
  		}
  	}
  }
}
.navmenu,
.navbar-offcanvas {
  width: $navmenu-width;
  border: none;
  border-radius: 0;
  ul.off-nav {
    padding: ceil($grid-gutter-width*.66);
    margin: 0;
    list-style: none;
    li {
      line-height: 1.1;
      margin-bottom: .5em;
      a {
        color: $white;
        text-decoration: none;
      }
    }
    >li {
      &.menu-item-has-children {
        ul.sub-menu {
//           display: none;
          padding: $grid-gutter-width/5 0;
          li {
            font-size: $font-size-sm;
          }
        }
      }
      &.active {
        >a {
          color: $brand-primary;
        }
      }
    }
    &.nav-primary {
      padding-bottom: $grid-gutter-width/5;

      >li {
        >a {
          font-size: $font-size-xl;
          font-weight: $font-weight-bold;
        }
      }
    }
    &.nav-secondary {
      padding-top: 0;
    }
  }
}
