p {
  margin: 0 0 1.2em;
}
.intro-text {
  font: $font-weight-light $font-size-xl $font-family-serif;
  line-height: 1.5;
  @media (max-width: $screen-xs-max) {
    font-size: $font-size-lg;
  }
}
.base-large {
  font: $font-size-xl $font-family-sans;
  line-height: 1.5;
}
.block-title {
  font: $font-weight-bold $font-size-base $font-family-sans;
  text-transform: uppercase;
  color: $gray-6;
}