body {
  letter-spacing: 0.05em;
  @media (min-width: $screen-sm-min) {
    padding-top: $navbar-height-ancillary-sm;
  }
  @media (min-width: $screen-md-min) {
    padding-top: $navbar-height-ancillary;
  }
  &.page-id-19320 {
    @media (min-width: $screen-sm-min) {
      padding-top: $navbar-height-ancillary-sm+80;
    }
    @media (min-width: $screen-md-min) {
      padding-top: $navbar-height-ancillary+80;
    }
  }
}
img {
  max-width: 100%;
  height: auto;
}
