// Left/right controls for nav
// ---------------------------
.carousel-inner {
  background-color: $black;
  .item {
    @extend .image-fullwidth;
  }
}
.carousel-control {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: $carousel-control-width;
  @include opacity($carousel-control-opacity);
  font-size: $carousel-control-font-size-xs;
  color: $carousel-control-color;
  text-align: center;
  text-shadow: $carousel-text-shadow;
  background-color: rgba(0, 0, 0, 0); // Fix IE9 click-thru bug
  // We can't have this transition here because WebKit cancels the carousel
  // animation if you trip this while in the middle of another animation.

  // Set gradients for backgrounds
  &.left {
    background-image: none;
    background: transparent;
  }
  &.right {
    left: auto;
    right: 0;
    background-image: none;
    background: transparent;
  }

  // Hover/focus state
  &:hover,
  &:focus {
    outline: 0;
    color: $carousel-control-color;
    text-decoration: none;
    @include opacity($carousel-control-opacity);
  }
  &:hover {
    .icon-prev,
    .icon-next,
    .glyphicon-triangle-left,
    .glyphicon-triangle-right {
      background-color: rgba($gray-3,.9);
      color: $gray;
    }
  }

  // Toggles
  .icon-prev,
  .icon-next,
  .glyphicon-triangle-left,
  .glyphicon-triangle-right {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    z-index: 5;
    display: inline-block;
    width: $carousel-control-width-xs;
    height: $carousel-control-width-xs;
    background-color: rgba($gray-3,.7);
    line-height: $carousel-control-width-xs;
  }
  .icon-prev,
  .glyphicon-triangle-left {
    left: 0;
    margin-left: 0;
  }
  .icon-next,
  .glyphicon-triangle-right {
    right: 0;
    margin-right:0;
  }
  .icon-prev,
  .icon-next {
    line-height: $carousel-control-width;
    font-family: serif;
  }


  .icon-prev {
    &:before {
      content: '\2039';// SINGLE LEFT-POINTING ANGLE QUOTATION MARK (U+2039)
    }
  }
  .icon-next {
    &:before {
      content: '\203a';// SINGLE RIGHT-POINTING ANGLE QUOTATION MARK (U+203A)
    }
  }
}

// Optional captions
// -----------------------------
// Hidden by default for smaller viewports
.carousel-caption {
  @extend .image-text-overlay;
}

// Scale up controls for tablets and up
@media screen and (min-width: $screen-sm-min) {

  // Scale up the controls a smidge
  .carousel-control {
    .glyphicon-triangle-left,
    .glyphicon-triangle-right,
    .icon-prev,
    .icon-next {
      width: $carousel-control-width-sm;
      height: $carousel-control-width-sm;
      font-size: $carousel-control-font-size-sm;
      line-height: $carousel-control-width-sm;
    }
    .glyphicon-triangle-left,
    .icon-prev {
      margin-left: 0;
    }
    .glyphicon-triangle-right,
    .icon-next {
      margin-right: 0;
    }
  }

  // Move up the indicators
  .carousel-indicators {
    bottom: 20px;
  }
}
@media screen and (min-width: $screen-md-min) {

  .carousel-control {
    .glyphicon-triangle-left,
    .glyphicon-triangle-right,
    .icon-prev,
    .icon-next {
      width: $carousel-control-width;
      height: $carousel-control-width;
      font-size: $carousel-control-font-size;
      line-height: $carousel-control-width;
    }
  }
}


/*
  Bootstrap Carousel Fade Transition (for Bootstrap 3.3.x)
  CSS from:       http://codepen.io/transportedman/pen/NPWRGq
  and:            http://stackoverflow.com/questions/18548731/bootstrap-3-carousel-fading-to-new-slide-instead-of-sliding-to-new-slide
  Inspired from:  http://codepen.io/Rowno/pen/Afykb 
*/
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

/*
  WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
  Need to override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .item.next,
    .carousel-fade .carousel-inner > .item.active.right {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.prev,
    .carousel-fade .carousel-inner > .item.active.left {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.next.left,
    .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
}