.easy-table {
  th, td {
    padding: $grid-gutter-width/2 0;
    line-height: $line-height-base;
    border-top: none;
    border-bottom: 1px solid $gray-8;
  }
}
.table-responsive {
  border: none;
}