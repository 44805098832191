.btn {
  padding: 20px;
//   padding-bottom: 20px;
  font-size: $font-size-lg;
  text-transform: uppercase;
  line-height: 1;
  border: none;
//   font-weight: $font-weight-base;
//   font-family: $font-family-sans-alt;
  &.active {
    @include box-shadow(none);
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    @include opacity(1);
    @include box-shadow(none);
  }
  &.btn-primary {
    &:hover {
      background-color: $brand-primary;
      color: $black;
    }
    &.disabled,
    &[disabled] {
      background-color: $input-bg;
      color: $input-color;
      &:hover {
        background-color: $input-bg;
        color: $input-color;
      }
    }
  }
  &.btn-action {
    background-color: $input-bg;
    color: $input-color;
    font-family: $font-family-sans-alt;
    &:hover {
      color: $brand-primary;
    }
    &.active {
      background-color: $brand-primary;
      &:hover {
        color: $input-color;
      }
    }
  }
  &.btn-trans {
    background-color: transparent;
    color: $white;
    border: 1px solid rgba($white,.8);
     &:hover {
      background-color: rgba($white,.8);
      color: $black;
      border: 1px solid rgba($white,.8);
    }
  }
  &:active,&:focus,&:hover {
    outline:none !important;
  }
  &.btn-sm {
    padding: 10px;
    font-size: $font-size-sm;
  }
}
button {
  &.btn {
    height: $input-item-height;
  }
  &.btn-sm {
    height: auto;
  }
}
