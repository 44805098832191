.bg-gray {
  background-color: $gray;
}
.bg-gold {
  background-color: $gold;
}
.bg-forest {
  background-color: $forest;
  color: $white;
  a {
    color: $white;
  }
  .block-title {
    color: $white;
  }
}
.bg-primary {
  background-color: $brand-primary;
  color: $white;
  a {
    color: $black;
  }
  .block-title {
    color: $white;
  }
}
.bg-whitetext {
  color: $white-true;
  a {
    color: $white-true;
    text-decoration: none;
    &:hover {
      color: $white;
    }
  }
}
.bg-salmon {
  background-color: $salmon;
  color: $black;
  a {
    color: $white;
  }
  .block-title {
    color: $black;
  }
}
.bg-white {
  background-color: $white;
}
.bg-black {
  background-color: $black-text;
  color: $white;
}
.bg-white-true {
  background-color: $white-true;
}
.bg-cherry {
  background-color: $cherry;
  @extend .bg-whitetext;
}
.bg-gold {
  background-color: $gold;
  @extend .bg-whitetext;
}
.bg-chocolate {
  background-color: $chocolate;
  @extend .bg-whitetext;
}
.section-30-60 {
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
  @media (min-width: $screen-sm-min) {
    padding-top: $grid-gutter-width*2;
//     padding-bottom: $grid-gutter-width*2;
    padding-bottom: $grid-gutter-width*1.5;
  }
}
.section-30-50 {
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
  @media (min-width: $screen-sm-min) {
    padding-top: ceil($grid-gutter-width*1.66);
//     padding-bottom: ceil($grid-gutter-width*1.66);
    padding-bottom: $grid-gutter-width*1.5;
  }
}

.section-30-40 {
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
  @media (min-width: $screen-sm-min) {
    padding-top: ceil($grid-gutter-width*1.33);
    padding-bottom: ceil($grid-gutter-width*1.33);
  }
}

.section-20 {
  padding-top: ceil($grid-gutter-width*.66);
  padding-bottom: ceil($grid-gutter-width*.66);
}
.bottom-wide-40-50 {
  margin-bottom: ceil($grid-gutter-width*1.33);
  @media (min-width: $screen-sm-min) {
    margin-bottom: ceil($grid-gutter-width*1.66);
  }
}
.bottom-wide-60-75 {
  margin-bottom: $grid-gutter-width*2;
  @media (min-width: $screen-sm-min) {
    margin-bottom: $grid-gutter-width*2.5;
  }
}
.image-fullwidth {
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
.image-text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  @include gradient-vertical(rgba($black-true,0), rgba($black-true,.9), 0%, 90%);
  text-shadow: none;
  text-align: left;
  padding: 20px 0 10px;
  @media (min-width: $screen-sm-min) {
    padding: 30px 0;
  }
  p.page-title {
    font: 700 $font-size-2xs $font-family-sans;
    text-transform: uppercase;
    color: $white;
    line-height: 1;
    margin: 0 0 .5em;
    @media (min-width: $screen-sm-min) {
      font-size: $font-size-base;
      width: auto;
      display: inline-block;
      background-color: rgba($black,.5);
      padding: 8px 15px;
      margin: 0 0 ceil($grid-gutter-width*.66);
      margin-left: -$grid-gutter-width/2;
    }
  }
  h1,h2 {
    margin: 0 0 .25em;
    line-height: 1;
    color: $white;
    font-size: $font-size-h6;
    @media (min-width: $screen-sm-min) {
      font-size: $font-size-h3;
    }
  }
  h2 {
    font-weight: $font-weight-light;
  }
  .exhibition-location {
    color: $white;
    margin-bottom: .4em;
  }
  date {
    font: 700 $font-size-xs $font-family-sans;
    text-transform: uppercase;
    color: $white;
    display: block;
    margin-top: $grid-gutter-width/6;
    @media (min-width: $screen-sm-min) {
      margin-top: $grid-gutter-width/2;
      font-size: $font-size-xl;
    }
  }
}
