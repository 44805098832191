.tribe-events-single .tribe-events-sub-nav .tribe-events-nav-previous a:before,
.tribe-events-single .tribe-events-sub-nav .tribe-events-nav-next a:after {
  background-size: 0;
  background-image: none;
}
.tribe-events-event-meta:before, .tribe-events-event-meta~div:not(.tribe-events-event-meta,.event-tickets) {
  border: none;
}
.tribe-events-abbr {
  border-bottom: none !important;
}
.tribe-events-event-meta a, .tribe-events-event-meta a:visited {
  color: $white;
}
.tribe-events-content {
  color: inherit;
  font-family: inherit;
  h2, h3, h4, h5, h6 {
    font-family: inherit;
  }
  a {
    border-bottom: inherit;
    color: $link-color;
    transition: inherit;
  }
}
#tribe-events-pg-template {
  max-width: none !important;
  padding: 0 !important;
}

.single-tribe_events .tribe-events .tribe-events-c-subscribe-dropdown {
  margin: 0;
  .tribe-events-c-subscribe-dropdown__button {
    background-color: $gray-6;
    border-radius: 0;
    color: $white;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    letter-spacing: .05em;
    padding: 10px 15px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    zoom: 1;
    &:hover {
      background-color: $brand-primary;
      color: $white;
    }
    font-family: inherit;
    line-height: 1;
    border: 0;
    width: auto;
  }
  .tribe-events-c-subscribe-dropdown__content {
    top: 30px;
  }
}

#tribe-events,body.tribe-events-page-template {
  .tribe-events-visuallyhidden {
    @extend .sr-only;
  }
  .tribe-events-button {
    background-color: $gray-6;
    border-radius: 0;
    color: $white;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    letter-spacing: .05em;
    &:hover {
      background-color: $brand-primary;
      color: $white;
    }
  }
  #tribe-events-content {
    margin-bottom: 0;
    padding: 0;
    #tribe-events-footer {
      margin: 0;
      padding: 0;
      border-top: none;
      .tribe-events-sub-nav {
        margin-bottom: 0;
        font-family: inherit;
        padding: $grid-gutter-width 0;
        @extend .bg-white-true;
        @media (min-width: $screen-md-min) {
          padding: $grid-gutter-width*2 0;
        }

        li {
          max-width: 50%;
          a {
            display: flex;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            font-size: $font-size-sm;
            padding: 0;
            background-color: transparent;
            color: $brand-primary;
            margin: 0;
            @media (min-width: $screen-sm-min) {
              font-size: $font-size-base;
            }
            &:before,&:after {
              display: none;
            }
            .glyphicon {
              position: relative;
              left: auto;
              top: auto;
              right: auto;
              display: block;
              float: left;
              text-align: center;
              width: $carousel-control-width-xs;
              min-width: $carousel-control-width-xs;
              height: $carousel-control-width-xs;
              background-color: rgba($gray-3,.7);
              line-height: $carousel-control-width-xs;
              font-size: $carousel-control-font-size-xs;
              color: $carousel-control-color;
              margin-top: -$grid-gutter-width/6;
              &.glyphicon-triangle-left {
                margin-right: $grid-gutter-width/6;
              }
              &.glyphicon-triangle-right {
                margin-left: $grid-gutter-width/6;
              }
              @media (min-width: $screen-sm-min) {
                width: $carousel-control-width-sm;
                min-width: $carousel-control-width-sm;
                height: $carousel-control-width-sm;
                font-size: $carousel-control-font-size-sm;
                line-height: $carousel-control-width-sm;
                &.glyphicon-triangle-left {
                  margin-right: $grid-gutter-width/3;
                }
                &.glyphicon-triangle-right {
                  margin-left: $grid-gutter-width/3;
                }
              }
              @media (min-width: $screen-md-min) {
                width: $carousel-control-width;
                min-width: $carousel-control-width;
                height: $carousel-control-width;
                font-size: $carousel-control-font-size;
                line-height: $carousel-control-width;
                margin-top: -$grid-gutter-width/3;
              }
            }
            .title {
              display: inline-block;
            }
            &:hover {
              color: $black;
              .glyphicon {
                outline: 0;
                color: $carousel-control-color;
                text-decoration: none;
                background-color: rgba($gray-3,.9);
                color: $gray;
              }
            }
          }
          &.tribe-events-nav-next {
            margin-right: 0;
            a {
              flex-direction: row-reverse;
              .glyphicon {
                float: right;
              }
            }
          }
        }
      }
    }
  }
  a {
    text-decoration: none;
  }
  .page-header {
    .page-title {
      a {
        color: $white;
        &:hover {
          color: $link-color;
        }
      }
    }
  }
  #tribebar {
    @extend .clearfix;
    position: relative;
    .catselect {
      background-color: $gray-6;
      text-align: center;
      position: inherit;
      .btn-group {
        width: 100%;
      }
      .dropdown-toggle {
        background-color: $gray-6;
        position: inherit;
        padding: $grid-gutter-width 0;
        margin: 0 auto;
        height: auto;
        width: 100%;
        color: $white;
        font: $font-weight-bold $font-size-sm $font-family-sans-alt;
        box-shadow: none;
        @media (min-width: $screen-sm-min) {
          font-size: $font-size-lg;
        }
        @media (min-width: $screen-lg-min) {
          font-size: $font-size-xl;
        }
      }
      .dropdown-menu {
        border: none;
        width: 100%;
        min-width: 130px;
      }
      .eventcat {
        border-radius: 0;
        margin: 0;
        padding: $grid-gutter-width/3;
        @media (min-width: $screen-sm-min) {
          padding: $grid-gutter-width/2 $grid-gutter-width;
        }
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            font-size: $font-size-sm;
            margin: $grid-gutter-width/2 0;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            @media (min-width: $screen-sm-min) {
              font-size: $font-size-base;
            }
            @media (min-width: $screen-md-min) {
              float: left;
              width: 50%;
            }
            a {
              color: $gray-3;
              text-decoration: none;
              &:hover {
                color: $brand-primary;
              }
            }
          }
        }
      }
    }
    .dateselect {
      background-color: $gray-3;
      text-align: center;
      input {
        text-align: center;
        padding: $grid-gutter-width/2 0;
        width: 100%;
        margin: $grid-gutter-width/2 auto;
        background-color: transparent;
        border: none;
        font: $font-weight-bold $font-size-sm $font-family-sans-alt;
        @media (min-width: $screen-sm-min) {
          font-size: $font-size-lg;
        }
        @media (min-width: $screen-lg-min) {
          font-size: $font-size-xl;
        }
        color: $white;
        text-transform: uppercase;
        &::-webkit-input-placeholder {
          color: $white;
    		}
    		&:-moz-placeholder { /* Firefox 18- */
          color: $white;
    		}
    		&::-moz-placeholder {  /* Firefox 19+ */
          color: $white;
    		}
    		&:-ms-input-placeholder {
          color: $white;
    		}
      }
    }
  }
  #tribe-events-bar {
    #tribe-bar-form {
      border-radius: 0;
      background-color: $gray-3;
      color: $white;
      .tribe-bar-date-filter {
        width: 100%;
        #tribe-bar-dates {
          text-align: center;
          display: none;
          label {
            font: $font-weight-bold $font-size-lg $font-family-sans-alt;
            margin: $grid-gutter-width 0 0;
          }
          input {
            width: 150px;
            height: 0;
            margin: 30px auto 0;
          }
        }
      }
    }
  }
  .tribe-bar-disabled {
    float: none;
  }
  .tribe-events-event-meta {
    font: $font-weight-bold $font-size-xl $font-family-sans;
    margin: 0;
    border: none;
    background-color: transparent;
    color: $gray-3;
    line-height: $line-height-base;
    flex-direction: column;
    margin-bottom: 10px;
    p {
      margin: 1.2em 0;
    }
  }
  .tribe-events-content {
    p:first-child {
      @extend .intro-text;
      margin-bottom: 1.5em;
    }
  }
  .tribe-events-list {
    padding: 0 !important;
    .type-tribe_events {
      @extend .bg-white-true;
      border: none;
      padding: 0;
      margin-bottom: $grid-gutter-width;
      color: $gray-3;
      date {
        display: block;
        padding: $grid-gutter-width/2 0 0 $grid-gutter-width/2;
        font-weight: $font-weight-bold;
        line-height: 1;
        @media (min-width: $screen-sm-min) {
          padding: ceil($grid-gutter-width*.66) 0 0 ceil($grid-gutter-width*.66);
        }
        span {
          display: block;
          text-transform: uppercase;
          &.date {
            font: $font-weight-bold $font-size-h1 $font-family-sans-alt;
            color: $brand-primary;
            line-height: 1.2;
            @media (min-width: $screen-md-min) {
              line-height: 1.3;
              font-size: $font-size-huge;
              margin-bottom: $grid-gutter-width/5;
            }
            @media (min-width: $screen-lg-min) {
              font-size: $font-size-huge2;
              margin-bottom: $grid-gutter-width/3;
            }
          }
        }
      }
      .details {
        padding-top: $grid-gutter-width/2;
        padding-bottom: $grid-gutter-width/2;
        @media (min-width: $screen-sm-min) {
          padding-top: $grid-gutter-width/3;
          padding-bottom: $grid-gutter-width/6;
        }
        @media (min-width: $screen-md-min) {
          padding-bottom: $grid-gutter-width/3;
        }
        @media (min-width: $screen-lg-min) {
          padding-top: $grid-gutter-width/2;
          padding-bottom: $grid-gutter-width/2;
        }
        time {
          color: $gray-6;
          text-transform: uppercase;
          font-family: $font-family-sans-alt;
          font-size: $font-size-sm;
          display: block;
          margin-bottom: 1.25em;
          @media (min-width: $screen-sm-min) {
            float: right;
            margin-bottom: 0;
          }
        }
        h2.event-title {
          font-size: $font-size-h6;
          line-height: 1.1;
          margin: .5em 0;
          display: block;
          @media (min-width: $screen-sm-min) {

          }
          @media (min-width: $screen-md-min) {
            font-size: $font-size-h5;
            margin: .75em 0;
          }
          @media (min-width: $screen-lg-min) {
            margin: 1em 0 2em;
          }
          a {
            color: $gray-3;
            &:hover {
              color: $brand-primary;
            }
          }
        }
        .terms {
          a {
            display: inline-block;
            padding: 5px 15px 2px;
            margin-right: 2px;
            line-height: 1;
            color: $gray-6;
            background-color: $gray;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            font-size: $font-size-sm;
            &:hover {
              color: $brand-primary;
            }
          }
        }
        .venue-details {
          font-weight: $font-weight-bold;
          font-size: $font-size-sm;
          color: $black;
        }
      }

    }
  }
}
