.block {
  @extend .section-30-40;
  .block-title {
    @extend .bottom-wide-40-50;
  }
  &.first {
    @extend .section-30-60;
    .block-title {
      margin-top: ceil($grid-gutter-width*.66);
      @extend .bottom-wide-60-75;
    }
  }
  &.second {
    padding-top: 0;
  }
  &.block-directory {
    .department {
      .department-title {
        font-size: $font-size-h5;
        font-weight: $font-weight-light;
      }
      .department-email {
        font-size: $font-size-sm;
      }
      .directory {
        @extend .clearfix;
        clear: left;
        .member {
          margin-bottom: $grid-gutter-width;
          article {
            background-color: $white-true;
            padding: $grid-gutter-width/2;
            line-height: 1.3;
//             font-family: $font-family-serif;
            h4 {
              margin: 0 0 .3em;
              font-size: $font-size-lg;
            }
            p {
              margin: 0 0 .15em;
              &.title {
                font-style: italic;
              }
            }
            h5 {
              font: $font-weight-bold $font-size-base $font-family-sans;
              margin: 1em 0 .5em;
            }
            ul {
              padding-left: 1.2em;
              font-style: italic;
            }
          }
        }

      }
    }
  }
  &.block-image-caption {
    @extend .section-20;
    .caption-wrap {
      @extend .bg-white-true;
      .caption {
        font-size: $font-size-sm;
        padding: $grid-gutter-width;
        @media (min-width: $screen-sm-min) {
          padding: ceil($grid-gutter-width*.66) $grid-gutter-width ceil($grid-gutter-width*.66) $grid-gutter-width/3;
        }
        @media (min-width: $screen-md-min) {
          padding: $grid-gutter-width ceil($grid-gutter-width*1.33) $grid-gutter-width ceil($grid-gutter-width*.66);
        }
        .title {
          font-size: $font-size-base;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
  &.block-image-full {
    padding: 0;
    @extend .image-fullwidth;
  }
  &.block-callout {
    padding: 0;
    @extend .image-fullwidth;
    position: relative;
    a {
      text-decoration: none;
    }
    article {
      position: absolute;
      width: 100%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      .info {
        line-height: 1.1;
        color: $white;
        h1,h2 {
          color: $white;
          margin: 0;
          font-size: $font-size-h6;
          @media (min-width: $screen-sm-min) {
            font-size: $font-size-h2;
          }
          @media (min-width: $screen-md-min) {
            font-size: $font-size-huge;
          }
          &.dark {
            color: $black;
          }
          span {
            font-weight: $font-weight-light;
          }
        }
        h2 {
          font-weight: $font-weight-light;
        }
        .description {
          font-family: $font-family-serif;
          font-weight: $font-weight-base;
          line-height: $line-height-base;
          color: $white;
          @media (min-width: $screen-sm-min) {
            font-size: $font-size-xl;
          }
          p {
            margin: 1em 0 0;
          }
          &.dark {
            color: $black;
          }
        }
        .btn {
          margin: 1em 0 0;
        }
      }
      @media (max-width: $screen-xs-max) {
        transform: none;
        top: auto;
        bottom: 0;
        padding-bottom: $grid-gutter-width/3;
        .info {
          .description,.btn {
            display: none;
          }
          h1 {
            span {
              display: block;
            }
          }
        }
      }
    }
  }
  &.block-text-columns {
    .col {
      @media (max-width: $screen-sm-max) {
        &:first-child {
          margin-bottom: $grid-gutter-width/2;
        }
      }
    }
    &.block-fullwidth {
      padding: 0;
      &>.container-fluid {
        padding: 0;
        &>.row {
          margin: 0;
          display: flex;
          flex: 1;
          flex-direction: column;
          @media (min-width: $screen-sm-min) {
            flex-direction: row;
          }
          &>.col {
            padding: $grid-gutter-width;
            display: flex;
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
            flex-direction: column;
            @media (min-width: $screen-sm-min) {
              padding: $grid-gutter-width*2;
              flex: 0 0 50%;
              width: 50%;
              max-width: 50%;
              align-self: center;
            }
            &.img-col {
              padding: 0;
              p {
                &:last-child {
                  margin-bottom: 0;
                }
              }
/*
              .wp-caption {
                margin-bottom: 0;
              }
*/
            }
            .wp-caption {
              padding: 0;
              background-color: $white;
              font-size: $font-size-sm;
              border: none;
              border-radius: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    &.block-text-columns-1 {
      &.block-fullwidth {
        &>.container-fluid {
          &>.row {
            &>.col {
              @media (min-width: $screen-sm-min) {
                flex: 0 0 100%;
                width: 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
  &.block-navigation{
    padding-top: 0;
    &.first {
      padding-top: $grid-gutter-width;
      @media (min-width: $screen-md-min) {
        padding-top: $grid-gutter-width*2;
      }
      .blog &,.press & {
        padding-top: $grid-gutter-width*4 !important;
      }
    }
    .navitem {
      margin-bottom: $grid-gutter-width;
      a {
        display: block;
        @extend .bg-white-true;
        color: $gray-3;
        text-decoration: none;
        &:hover {
          color: $brand-primary;
        }
        .detail {
          margin-left: -$grid-gutter-width/2;
          padding: $grid-gutter-width/3;
          line-height: 1.1;
          h3 {
            font: $font-weight-bold $font-size-base $font-family-serif;
            margin: 0;
          }
          h4 {
            font: $font-weight-base $font-size-base $font-family-serif;
            margin: .25em 0 0;
            display: none;
          }
          date {
            display: block;
            font: $font-weight-bold $font-size-sm $font-family-sans;
            margin: .25em 0 0;
            text-transform: uppercase;
          }
          @media (min-width: $screen-sm-min) {
            padding: $grid-gutter-width/2;
            h4 {
              display: block;
            }
          }

        }
        @media (min-width: $screen-md-min) {
          .detail {
            padding: ceil($grid-gutter-width*.66);
          }
        }
      }
      &.exhibition {
        a {
          .detail {
            @media (min-width: $screen-sm-min) {
              padding: $grid-gutter-width;
              h4 {
                margin-top: .5em;
              }
              h3,h4 {
                font-size: $font-size-lg;
              }
              date {
                margin-top: .75em;
                font-size: $font-size-base;
              }
            }
            @media (min-width: $screen-md-min) {
              padding: ceil($grid-gutter-width*1.33);
              h3,h4 {
                font-size: $font-size-xl;
              }
              date {
                font-size: $font-size-lg;
              }
            }
          }
        }
      }
      &.stack {
        a {
          .detail {
            @media (max-width: $screen-xs-max) {
              margin: 0;
              padding: $grid-gutter-width/3 $grid-gutter-width;
              line-height: 1.2;
              date {
                margin-top: .75em;
              }
            }
          }
        }
      }
    }
  }
  &.block-navigation-box {
    padding-top: 0;
    padding-bottom: 0;
    .navitem {
      margin-bottom: $grid-gutter-width;
      a {
        display: block;
        .detail {
          padding: $grid-gutter-width/2 $grid-gutter-width;
          p {
            margin: .5em 0 0;
            font-size: $font-size-sm;
            &.title {
              margin-top: 0;
              text-transform: uppercase;
              font-size: $font-size-base;
            }
          }
          @media (min-width: $screen-sm-min) {
            margin-left: -$grid-gutter-width/2;
            padding: $grid-gutter-width;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          @media (min-width: $screen-md-min) {
            padding: ceil($grid-gutter-width*1.33);
            p {
              font-size: $font-size-base;
              &.title {
                font-size: $font-size-lg;
              }
            }
          }
        }
      }
    }
  }
  &.block-links {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      border-top: 1px solid $gray-6;
      li {
        border-bottom: 1px solid $gray-6;
        a {
          display: block;
          text-decoration: none;
          padding: ceil($grid-gutter-width*.66) 0;
          color: $black;
          font-size: $font-size-xl;
          &:hover {
            color: $link-color;
          }
          span {
            float: right;
            font-size: $font-size-2xl;
            margin-left: $grid-gutter-width/2;
          }
        }
      }
    }
  }
  &.block-membership {
    padding: 0;
    @extend .bg-white-true;
  }
  &.block-video {
    padding-top: 0;
  }
  &.block-video-full {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &.block-related-pages {
    padding-top: 0;
    .block-title {
      margin-bottom: ceil($grid-gutter-width*.66);
    }
    .related-page {
      margin-bottom: $grid-gutter-width;
      a {
        color: $gray-3;
        text-decoration: none;
        &:hover {
          color: $brand-primary;
        }
        article {
          @extend .bg-white-true;
          padding: $grid-gutter-width/2;
          font: $font-weight-bold $font-size-base $font-family-serif;
        }
      }
    }

  }
  &.block-activities {
    padding-top: 0;
    padding-bottom: 0;
    .block-activity {
      @extend .section-30-40;
      @extend .bg-white-true;
      border-bottom: 1px solid $gray-b;
      &:first-child {
        border-top: 1px solid $gray-b;
      }
      .description {
        h3,h4 {
          font-size: $font-size-h5;
          margin: 0;
          line-height: $line-height-base;
        }
        h4 {
          font-weight: $font-weight-light;
        }
        article {
          margin-top: 1em;
        }
      }
      .details {
        font: $font-family-sans-alt;
        font-weight: $font-weight-bold;
        color: $gray-3;
        p {
          margin-bottom: .5em;
          line-height: 1.2;
          b {
            text-transform: uppercase;
            color: $gray-6;
          }
          .btn {
            margin-bottom: 1em;
          }
        }
      }
    }
  }
}
