article.hentry {
  .entry-title {
    @extend h5;
    margin-bottom: $grid-gutter-width;
  }
  .single-collection & {
//     @extend .intro-text;
  }
  .single-exhibition & {
    .exhibition-location {
      font-style: italic;
    }
    .timingline {
      margin-top: $grid-gutter-width*1.5;
      margin-bottom: $grid-gutter-width;
      @media (min-width: $screen-sm-min) {
        margin-top: $grid-gutter-width*2;
      }
      .block-title {
        margin-bottom: ceil($grid-gutter-width*.66);
      }
      p {
        margin: 0;
        &.opening {
          margin-bottom: 1em;
        }
        &.closing {
          margin-top: 1em;
        }
      }
      date {
        font-weight: $font-weight-bold;
        text-transform: uppercase;
      }
    }
  }
}
.woocommerce {
  .product-addon {
    padding: $grid-gutter-width $grid-gutter-width*.66 $grid-gutter-width/6;
    background-color: $gray-6;
    color: $white;
    h3 {
      font: $font-weight-bold $font-size-h6 $font-family-sans;
      text-transform: uppercase;
    }
    .addon-description {
      p {
        font-style: normal;
        font-size: $font-size-sm;
      }
    }
  }
  #respond input#submit.alt, a.button.alt, button.button.alt, input.button.alt {
    @extend .btn;
    @extend .btn-primary;
  }


  a.remove {
  	color: $cherry !important;
  	&:hover {
    	background-color: $cherry;
  	}
  }
  div.product {
    .product-header {
      margin-bottom: $grid-gutter-width;
      .product_title {
        font-size: $font-size-h4;
        margin: 0;
        line-height: 1.2;
        @media (min-width: $screen-sm-min) {
          font-size: $font-size-h3;
        }
      }
      .product_secondary_title {
        font-size: $font-size-h4;
        margin: 0;
        font-weight: $font-weight-light;
        line-height: 1.2;
        @media (min-width: $screen-sm-min) {
          font-size: $font-size-h3;
        }
      }
    }
    .price {
      font: $font-weight-bold $font-size-base $font-family-sans-alt !important;
      color: $text-color !important;
      margin-bottom: $grid-gutter-width;
    }
    form.cart {
      margin-bottom: $grid-gutter-width;
      div.quantity {
        float: none;
        label {
          font: $font-weight-bold $font-size-base $font-family-sans-alt;
        }
        .form-control {
          background-color: $gray;
          color: $text-color;
          text-align: center;
          border: none;
          box-shadow: none;
        }
      }
    }
    .product_meta {
      margin-top: $grid-gutter-width;
      font: $font-weight-bold $font-size-xs $font-family-sans-alt;
      p {
        margin-bottom: 0;
      }
      .sku_wrapper {
        display: block;
      }
    }
  }

  .products {
    .product {
      width:100% !important;
      padding: 0 $grid-gutter-width/2 !important;
      margin: 0 !important;
      @media (min-width: $screen-sm-min) {
        width: 50% !important;
      }
      .price {
        font: $font-weight-bold $font-size-base $font-family-sans-alt !important;
        color: $text-color !important;
      }
      .thm {
        a {
          img {
            margin: 0;
          }
        }
      }
      article {
        @extend .clearfix;
        background-color: $white-true;
        margin-bottom: $grid-gutter-width;
        padding-top: $grid-gutter-width/2;
        padding-bottom: $grid-gutter-width/2;
        .desc {
          a,button {
            text-decoration: none;
            h3,h2 {
              margin: 0;
              font-size: $font-size-base;
              color: $gray-3;
            }
            .price {
              display: block;
              margin: ceil($grid-gutter-width*.66) 0;
            }
            &.add_to_cart_button {
              @extend .btn;
              @extend .btn-primary;
              @extend .btn-sm;
            }
            &.added_to_cart {
              display: block;
              font: $font-weight-bold $font-size-xs $font-family-sans-alt;
              color: $text-color;
              text-transform: uppercase;
            }
            &:hover {
              h3,h2,&.added_to_cart {
                color: $link-color;
              }
            }
          }
        }
      }
    }
  }
  .feat-prod-wrap {
    background-color: $greengray;
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    @media (min-width: $screen-sm-min) {
      padding-top: $grid-gutter-width*2;
      padding-bottom: $grid-gutter-width*2;
    }
    .carousel-inner {
      background-color: $greengray;
      .item {
        .row {
          .desc {
            h3,h4 {
              color: $white;
              font-size: $font-size-h4;
              line-height: 1.2;
              margin: 0;
              @media (min-width: $screen-sm-min) {
                font-size: $font-size-h3;
              }
            }
            h4 {
              font-weight: $font-weight-light;
            }
            @media (max-width: $screen-xs-max) {
              h3 {
                margin-top: $grid-gutter-width;
              }
            }
            .btn {
              margin-top: $grid-gutter-width;
            }
          }
          @media (min-width: $screen-sm-min) {
            .thm {
              float: right;
              text-align: center;
            }
            .desc {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
  form {
    .form-row {
      input.input-text, textarea {
    		&::-webkit-input-placeholder {
    		  color: $gray-b;
    		  font-weight: $font-weight-light;
    		  font-size: $font-size-base;
    		}
    		&:-moz-placeholder { /* Firefox 18- */
    		  color: $gray-b;
    		  font-weight: $font-weight-light;
    		  font-size: $font-size-base;
    		}
    		&::-moz-placeholder {  /* Firefox 19+ */
    		  color: $gray-b;
    		  font-weight: $font-weight-light;
    		  font-size: $font-size-base;
    		}
    		&:-ms-input-placeholder {
    		  color: $gray-b;
    		  font-weight: $font-weight-light;
    		  font-size: $font-size-base;
    		}
    		&:focus {
      		&::-webkit-input-placeholder {
      		  color: transparent;
      		}
      		&:-moz-placeholder { /* Firefox 18- */
      		  color: transparent;
      		}
      		&::-moz-placeholder {  /* Firefox 19+ */
      		  color: transparent;
      		}
      		&:-ms-input-placeholder {
      		  color: transparent;
      		}
      	}
      }
    }
  }
}
.woocommerce-message {
	border-top-color: $brand-primary;
	&:before {
		color: $brand-primary;
	}
}
.cart {
  .block-cart {
    .quantity {
      .form-group {
        margin: 0;
        .form-control {
          background-color: $gray;
          color: $gray-3;
          box-shadow: none;
          height: auto;
        }
        label {
          display: none;
        }
      }
    }
  }
}
.checkout {
  .woocommerce-billing-fields,.woocommerce-shipping-fields,.woocommerce-additional-fields {
    .form-row {
      @extend .form-group;
    }
    input:not([type='checkbox']),textarea,select,.select2-choice,.select2-selection {
      @extend .form-control;
      background-color: $gray;
      color: $gray-3;
      box-shadow: none;
    }
    .select2-choice,.select2-selection {
      padding-top: $grid-gutter-width/2;
      height: auto;
      min-height: $input-item-height;
    }
  }
}

.posts-navigation {
  clear:both;
  .nav-links {
    padding: $grid-gutter-width 0;
    @extend .bg-white-true;
    @extend .clearfix;
    @media (min-width: $screen-md-min) {
      padding: $grid-gutter-width*2 0;
    }
    .nav-previous {
      float: left;
      text-align: left;
    }
    .nav-next {
      float: right;
      text-align: right;
    }
    .nav-previous,.nav-next {
      a {
        text-transform: uppercase;
        text-decoration: none;
        font-weight: $font-weight-bold;
        font-size: $font-size-sm;
        padding: 0;
        background-color: transparent;
        color: $brand-primary;
        margin: 0;
        @media (min-width: $screen-sm-min) {
          font-size: $font-size-base;
        }
        .glyphicon {
          position: relative;
          left: auto;
          top: auto;
          right: auto;
          display: block;
          float: left;
          text-align: center;
          width: $carousel-control-width-xs;
          height: $carousel-control-width-xs;
          background-color: rgba($gray-3,.7);
          line-height: $carousel-control-width-xs;
          font-size: $carousel-control-font-size-xs;
          color: $carousel-control-color;
          margin-top: -$grid-gutter-width/6;
          &.glyphicon-triangle-left {
            margin-right: $grid-gutter-width/6;
          }
          &.glyphicon-triangle-right {
            margin-left: $grid-gutter-width/6;
            float: right;
          }
          @media (min-width: $screen-sm-min) {
            width: $carousel-control-width-sm;
            height: $carousel-control-width-sm;
            font-size: $carousel-control-font-size-sm;
            line-height: $carousel-control-width-sm;
            &.glyphicon-triangle-left {
              margin-right: $grid-gutter-width/3;
            }
            &.glyphicon-triangle-right {
              margin-left: $grid-gutter-width/3;
            }
          }
          @media (min-width: $screen-md-min) {
            width: $carousel-control-width;
            height: $carousel-control-width;
            font-size: $carousel-control-font-size;
            line-height: $carousel-control-width;
            margin-top: -$grid-gutter-width/3;
          }
        }
        &:hover {
          color: $black;
          .glyphicon {
            outline: 0;
            color: $carousel-control-color;
            text-decoration: none;
            background-color: rgba($gray-3,.9);
            color: $gray;
          }
        }
      }
    }
  }
}

/*
.woocommerce ul.products li.product, .woocommerce-page ul.products li.product {
    float: left;
    margin: 0 3.8% 2.992em 0;
    padding: 0;
    position: relative;
    width: 22.05%;
}
*/
