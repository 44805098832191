//== Glyphicons font path
$icon-font-path:        "../fonts/";

//== Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

//== Colors
$brand-primary:         #3FB98C;
$salmon:								#F27060;
$cherry:								#ef4138;
$gold:								  #bb8b2c;
$chocolate:							#726658;
$forest:                #3a453b;

$white:									#F7F7F7;
$white-true:						#fff;
$black:									#231F20;
$black-text:            #1d1d1d;
$black-true:						#000;
$black-light:           #404041;

$gray:									#E6E7E7;
$gray-b:                #b1b1b1;
$gray-8:								#8b8b8b;
$gray-6:								#67676a;
$gray-3:								#3C3C3B;

$greengray:             #3a453b;



//== Scaffolding
$body-bg:               $white;
$text-color:            $black-text;
$footer-text-color:			$black-light;

$link-color:            $brand-primary;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: underline;


//== Typography
$font-family-sans:  			"ScalaSans","Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-sans-alt:  	"Roboto","Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       'Stag Web',Georgia, "Times New Roman", Times, serif;
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans;
$font-weight-light:				300;
$font-weight-base:				400;
$font-weight-bold:				700;
$font-weight-black:				900;

$font-size-base:          15px;
$font-size-large:         ceil(($font-size-base * 1.2)); // ~18px
$font-size-small:         ceil(($font-size-base * 0.8)); // ~12px

$font-size-2xs:           ceil(($font-size-base * 0.6)); // ~9px (caption)
$font-size-xs:            ceil(($font-size-base * 0.733)); // ~11px (footer)
$font-size-sm:            $font-size-small;  // ~12px
$font-size-lg:            $font-size-large;  // ~18px (button)
$font-size-xl:            ceil(($font-size-base * 1.4)); // ~21px
$font-size-2xl:           ceil(($font-size-base * 1.65)); // ~25px
$font-size-3xl:           ceil(($font-size-base * 1.866)); // ~28px

$font-size-huge:          ceil(($font-size-base * 2.8)); // ~42px (home titles)
$font-size-huge2:         ceil(($font-size-base * 4.266)); // ~64px (date #)

$font-size-h1:            ceil(($font-size-base * 2.65)); // ~40px
$font-size-h2:            floor(($font-size-base * 2.15)); // ~32px
$font-size-h3:            ceil(($font-size-base * 1.866)); // ~28px
$font-size-h4:            ceil(($font-size-base * 1.65)); // ~25px
$font-size-h5:            ceil(($font-size-base * 1.4)); // ~21px
$font-size-h6:            ceil(($font-size-base * 1.2)); // ~18px

$line-height-base:        1.428571429; // 20/14
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px

$headings-font-family:    $font-family-serif;
$headings-font-weight:    $font-weight-bold;
$headings-line-height:    1.1;
$headings-color:          inherit;

//== Grid system
$grid-columns:              12;
$grid-gutter-width:         30px;

//== Navbar
$navbar-height:                    80px;
$navbar-height-ancillary:          48px;
$navbar-height-full:               $navbar-height + $navbar-height-ancillary;
$navbar-height-sm:                 60px;
$navbar-height-ancillary-sm:       27px;
$navbar-height-full-sm:            $navbar-height + $navbar-height-ancillary-sm;
$navbar-height-full-xs:            $navbar-height-sm + $navbar-height-ancillary-sm;
$navbar-default-color:             $white;
$navbar-default-bg:                rgba($black,.6);
$navbar-default-border:            transparent;
// Navbar links
$navbar-default-link-color:                $white-true;
$navbar-default-link-hover-color:          $gray;
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         $white-true;
$navbar-default-link-active-bg:            transparent;
// Navbar brand label
$navbar-default-brand-color:               $navbar-default-link-color;
$navbar-default-brand-hover-color:         $gray;
$navbar-default-brand-hover-bg:            transparent;
// Navbar toggle
$navbar-default-toggle-hover-bg:           transparent;
$navbar-default-toggle-icon-bar-bg:        $white;
$navbar-default-toggle-border-color:       transparent;

$navmenu-width:                             300px;

//== Dropdowns
$dropdown-bg:                    rgba($white,.9);
$dropdown-border:                red;
$dropdown-fallback-border:       red;
$dropdown-link-color:            $gray-3;
$dropdown-link-hover-color:      $link-color;
$dropdown-link-hover-bg:         transparent;
$dropdown-link-active-color:     $dropdown-link-color;
$dropdown-link-active-bg:        $dropdown-link-hover-bg;

//== Forms
$input-bg:                       $gray-3;
$input-bg-disabled:              $black-text;
$input-color:                    $white;
$input-border:                   transparent;
$input-border-radius:            0;
$input-border-radius-large:      0;
$input-border-radius-small:      0;
$input-border-focus:             $brand-primary;
$input-color-placeholder:        $gray;
$input-item-height:              $grid-gutter-width*2;
$form-group-margin-bottom:       15px;

//== Buttons
$btn-font-weight:                $font-weight-black;
$btn-default-color:              $black;
$btn-default-bg:                 $gray;
$btn-default-border:             $btn-default-bg;
$btn-primary-color:              $white;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $btn-primary-bg;
$btn-link-disabled-color:        $gray-6;
$btn-border-radius-base:         0;
$btn-border-radius-large:        0;
$btn-border-radius-small:        0;

//== Carousel
$carousel-text-shadow:                        none;
$carousel-control-color:                      $gray-b;
$carousel-control-width-xs:                   ceil($grid-gutter-width*.66);
$carousel-control-width-sm:                   $grid-gutter-width;
$carousel-control-width:                      ceil($grid-gutter-width*1.5);
$carousel-control-opacity:                    1;
$carousel-control-font-size-xs:               $font-size-sm;
$carousel-control-font-size-sm:               $font-size-base;
$carousel-control-font-size:                  20px;
$carousel-indicator-active-bg:                $white;
$carousel-indicator-border-color:             $white;
$carousel-caption-color:                      $white;

