.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}



			.slide-double-feature {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.slide-double-feature img {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -o-object-fit: cover;
       object-fit: cover;
    max-width: 50%;
}

.wow-modal-overlay { overflow: scroll !important; }
.wow-modal-window { position: absolute !important; background: #67676A !important; }
.modal-window-content { overflow: hidden; }
.mw-close-btn.text::before { content: "\2715" !important; color: #fff !important; padding: 0 !important; font-size: 40px !important; background: unset !important; top: 0.5rem; right: 1rem; position: absolute; }
.slide-modal { padding: 4rem 3.5rem; }
.slide-modal .slide-modal-top { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.slide-modal .slide-modal-feature { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: column; flex-flow: column; }
.slide-modal a { color: #fff; text-decoration: none; }
.slide-modal .slide-arrow { display: block; font-size: 24px; }
div.slide-modal a.slide-arrow.slide-arrow-right i {
    margin-left: 1.5rem;
}
.slide-modal span[id^="wow-modal-close-"] .fas {
  color: #fff;
  font-size: 24px;
  position: absolute;
	cursor: pointer;
}

.slide-modal .fas { cursor: pointer }

.slide-modal .fas.fa-chevron-left {
  left: 0.5rem;
}

.slide-modal .fas.fa-chevron-right {
  right: 0.5rem;
}
div.slide-modal a.slide-arrow i {
    position: absolute;
}
div.slide-modal a.slide-arrow.slide-arrow-left i {
    left: 0.5rem;
}
.slide-modal img { width: 100%; height: auto; }
.slide-modal .slide-modal-links { margin: 1.5rem 0; }
.slide-modal .slide-modal-links a { display: block; margin: 0.5rem; font-size: 16px; }
.slide-modal .slide-modal-links a:last-child { margin-bottom: 0; }
.slide-modal .slide-modal-details { background: #fff; padding: 1.5rem 2.5rem; font-family: Stag }
.slide-modal .slide-modal-details p { font-size: 18px !important; line-height: 24px; margin: 0.5rem 0; }
.slide-modal .slide-modal-details p:first-child { margin-bottom: 2rem; }
.slide-modal .slide-modal-links a[data-action="play"]::before {

    content: "\0025B6";
    margin-right: 0.5rem;

}

.slide-modal .slide-modal-links a[data-action="info"]::before {

    content: "\01F6C8";
    margin-right: 0.5rem;

}
div.desktop-flex.flex-padding-m .flex-box img {

    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;

}

@media only screen and (min-width: 768px) {

div.desktop-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

div.desktop-flex.flex-padding-m .flex-box:first-child {

    margin-right: 1rem;

}

div.desktop-flex.flex-padding-m .flex-box:last-child {

    margin-left: 1rem;

}

div.desktop-flex.flex-padding-m .flex-box:not(:first-child):not(:last-child) {

    margin: 0 1rem;

}

div.desktop-flex.flex-padding-m {

    margin-bottom: 2rem;

}

div.desktop-flex.flex-padding-m .flex-box {

    width: 250px;
    height: 250px;

}

}

@media only screen and (max-width: 768px) {
div.desktop-flex.flex-padding-m .flex-box {

    width: 300px;
    height: 300px;
    display: block;
    margin: 1rem auto;

}

}

.slide-modal p span.link {
	color: #3fb98c;
}

.slide-modal p span.link:hover {
	color: #2c8061;
	text-decoration: underline;
	cursor: pointer;
}
