// Search form
.search-form {
  @extend .form-inline;
  &.slim {
    border-bottom: 1px solid $gray-6;
/*
    label {
      color: $white;
      width: 70px;
      text-align: center;
      margin: 0;
      i {
        font-size: $font-size-xl;
      }
    }
    .form-group {
      margin: 0;
    }
*/
    .search-field {
      margin: 0;
      height: 50px;
      width: 100%;
      display: inline-block;
      font-weight: $font-weight-base;
      font-size: $font-size-base;
      padding-left: 45px;
      background: transparent url(../images/bg/search.png) no-repeat 15px center;
      background-size: 15px auto;
    }
  }
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}
.form-control {
  height: $input-item-height;
  font: $font-weight-black $font-size-lg $font-family-sans-alt;
  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
}

#checkout.donation {
  section,.description {
    display: none;
    &.active {
      display: block;
    }
    .actionstep {
      margin-top: $grid-gutter-width;
    }
    .form-group {
      margin-bottom: $grid-gutter-width;
      .radio {
        input {
          position: relative;
          margin-left: 0;
        }
      }
      textarea {
        height: $grid-gutter-width*3;
        &::-webkit-input-placeholder {
    		  color: rgba($gray,.7);
    		  font-weight: $font-weight-base;
    		  font-size: $font-size-base;
    		}
    		&:-moz-placeholder { /* Firefox 18- */
    		  color: rgba($gray,.7);
    		  font-weight: $font-weight-base;
    		  font-size: $font-size-base;
    		}
    		&::-moz-placeholder {  /* Firefox 19+ */
    		  color: rgba($gray,.7);
    		  font-weight: $font-weight-base;
    		  font-size: $font-size-base;
    		}
    		&:-ms-input-placeholder {
    		  color: rgba($gray,.7);
    		  font-weight: $font-weight-base;
    		  font-size: $font-size-base;
    		}
  		  &:focus {
      		&::-webkit-input-placeholder {
      		  color: transparent !important;
      		}
      		&:-moz-placeholder { /* Firefox 18- */
      		  color: transparent !important;
      		}
      		&::-moz-placeholder {  /* Firefox 19+ */
      		  color: transparent !important;
      		}
      		&:-ms-input-placeholder {
      		  color: transparent !important;
      		}
      	}
      }
    }
    .heighten {
      .form-group {
        height: $grid-gutter-width*2;
        &.message {
          text-align: center;
          text-transform: uppercase;
          font: $font-weight-bold $font-size-lg $font-family-sans;
          margin-top: $grid-gutter-width/2;
        }
      }
    }
  }
  .newsletter-agree {
    text-align: center;
    margin-bottom: 0;
    label {
      font-weight: $font-weight-base;
    }
    input[type=checkbox] {
      margin: 0 10px 0 0;
    }
  }
  .stages {
    margin-top: $grid-gutter-width/2;
    margin-bottom: ceil($grid-gutter-width*1.33);
    .stage {
      p {
        text-transform: uppercase;
        text-align: center;
        border-bottom: 1px solid $gray-6;
        margin: 0;
        line-height: 1;
        padding: 8px 0;
        color: $gray-6;
        @media (max-width: $screen-xs-max) {
          font-size: $font-size-xs;
        }
      }
      &.active {
        p {
          color: $white;
          border-color: $brand-primary;
        }
      }
    }
  }
  .amounts {
    .form-control {
      text-align: center;
      &.empty {
        font-size: $font-size-base;
        font-weight: $font-weight-base;
      }
    }
    .btn {
      width: 100%;
    }
  }
  .btn {
    &.next,&[type=submit] {
      width: 100%;
      display: block;
    }
  }
}
